import React from 'react';
import styled, { keyframes } from 'styled-components';

const neonFlicker = keyframes`
  0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
    text-shadow: 
      -0.2rem -0.2rem 1rem #fff,
      0.2rem 0.2rem 1rem #fff,
      0 0 2rem #f40,
      0 0 4rem #f40,
      0 0 6rem #f40,
      0 0 8rem #f40,
      0 0 10rem #f40;
    box-shadow: 
      0 0 .5rem #fff,
      inset 0 0 .5rem #fff,
      0 0 2rem #08f,
      inset 0 0 2rem #08f,
      0 0 4rem #08f,
      inset 0 0 4rem #08f;
  }
  20%, 24%, 55% {       
    text-shadow: none;
    box-shadow: none;
  }
`;

const ForumContainer = styled.div`
  padding: 30px;
  background: linear-gradient(45deg, #0f0c29, #302b63, #24243e);
  border-radius: 10px;
  color: #00ffff;
  font-family: 'Orbitron', sans-serif;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.5);
  margin: 20px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: repeating-linear-gradient(
      to bottom,
      transparent,
      transparent 20px,
      rgba(0, 255, 255, 0.05) 20px,
      rgba(0, 255, 255, 0.05) 40px
    );
    animation: ${neonFlicker} 5s infinite;
    pointer-events: none;
  }
`;

const ForumTitle = styled.h2`
  color: #ff00ff;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-shadow: 0 0 10px #ff00ff, 0 0 20px #ff00ff, 0 0 30px #ff00ff;
`;

const PurpleText = styled.span`
  color: #bf00ff;
  text-shadow: 0 0 5px #bf00ff, 0 0 10px #bf00ff;
`;

const Paragraph = styled.p`
  margin-bottom: 20px;
  line-height: 1.6;
  font-size: 1.1rem;
  text-shadow: 0 0 5px #00ffff;
`;

const Forum = () => {
  return (
    <ForumContainer>
      <ForumTitle>Document</ForumTitle>
      <Paragraph>
        Depuis toujours, j'ai été attiré par les musiques rapides, électro dynamiques et la synthwave. Ce goût m'a entraîné à créer ce site pour partager avec vous ces genres musicaux qui me fascinent tant.
      </Paragraph>
      <Paragraph>
        Je souhaite partager mes découvertes musicales, même si mes goûts sont assez aléatoires. Mon site est <PurpleText>pour ceux qui apprécient la synthwave, la pop et l'électro.</PurpleText>
      </Paragraph>
      <Paragraph>
        Mon objectif principal est de <PurpleText>constituer un grand répertoire de musique.</PurpleText> Je veux montrer aux visiteurs des musiques pour qu'ils puissent <PurpleText>explorer et apprécier ce genre.</PurpleText>
      </Paragraph>
      <Paragraph>
        Pour l'instant, le site se concentre <PurpleText>exclusivement sur la musique.</PurpleText> 
      </Paragraph>
      <Paragraph>
        Le site propose principalement de la <PurpleText>musique synthwave, pop et electro.</PurpleText> Vous y trouverez une variété de morceaux pour satisfaire vos oreilles.
      </Paragraph>
      <Paragraph>Je montre mon site sur Instagram.</Paragraph>
      <Paragraph>
        J'encourage les visiteurs à me faire part de leurs retours en m'envoyant des messages privés sur Instagram. (je ne mange pas (pas encore))
      </Paragraph>
    </ForumContainer>
  );
};

export default Forum;
