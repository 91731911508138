import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaPlay, FaPause, FaForward, FaBackward, FaVolumeUp, FaVolumeMute, FaChevronDown, FaChevronUp, FaRandom, FaRedo } from 'react-icons/fa';

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px #ff00ff, 0 0 10px #ff00ff, 0 0 15px #ff00ff, 0 0 20px #ff00ff; }
  50% { box-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff, 0 0 30px #00ffff, 0 0 40px #00ffff; }
  100% { box-shadow: 0 0 5px #ff00ff, 0 0 10px #ff00ff, 0 0 15px #ff00ff, 0 0 20px #ff00ff; }
`;

const scanlineAnimation = keyframes`
  0% { transform: translateY(-100%); }
  100% { transform: translateY(100%); }
`;

const PlayerContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: #00ffff;
  border-top: 2px solid #ff00ff;
  border-radius: 10px 10px 0 0;
  padding: 20px;
  z-index: 1000;
  transform: ${(props) => props.minimized ? 'translateY(calc(100% - 60px))' : 'translateY(0)'};
  transition: transform 0.3s ease;
  animation: ${glowAnimation} 3s infinite alternate;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(
      0deg,
      transparent 50%,
      rgba(32, 128, 255, 0.2) 50%
    );
    background-size: 100% 4px;
    animation: ${scanlineAnimation} 4s linear infinite;
    pointer-events: none;
  }
`;

const PlayerControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
`;

const Button = styled.button`
  background: transparent;
  border: none;
  color: #00ffff;
  font-size: 1.5rem;
  cursor: pointer;
  outline: none;
  transition: color 0.3s, transform 0.2s;

  &:hover {
    color: #ff00ff;
    transform: scale(1.1);
  }

  &:disabled {
    color: #333;
  }
`;

const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const TrackTitle = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  color: #ff00ff;
  text-shadow: 0 0 5px #ff00ff, 0 0 10px #ff00ff;
`;

const TrackArtist = styled.p`
  margin: 5px 0;
  color: #00ffff;
  text-shadow: 0 0 5px #00ffff, 0 0 10px #00ffff;
`;

const ProgressBar = styled.input`
  width: 100%;
  margin: 10px 0;
  -webkit-appearance: none;
  background: transparent;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, #ff00ff, #00ffff);
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    margin-top: -6px;
    box-shadow: 0 0 10px #ffffff;
  }

  @media (max-width: 600px) {
    &::-webkit-slider-runnable-track {
      height: 12px;
    }
    &::-webkit-slider-thumb {
      height: 24px;
      width: 24px;
      margin-top: -6px;
    }
  }
`;

const ToggleButton = styled.button`
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: #ff00ff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  box-shadow: 0 0 10px #ff00ff;
  z-index: 1001;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px #ff00ff;
  }
`;

const ControlsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const VolumeControl = styled.input`
  -webkit-appearance: none;
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #ff00ff, #00ffff);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
  }
`;

const TimeDisplay = styled.span`
  font-size: 0.8rem;
  color: #00ffff;
`;

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
}
// Example track list
const tracks = [
  { title: 'Dans La Nuit', src: '/audio/Dans la nuit.mp3'},
  { title: 'Loin du temps', src: '/audio/Loin du Temps.mp3'},
  { title: 'Vortex Nocturne', src: '/audio/Vortex Nocturne.mp3'},
  { title: 'La Course Sans Fin', src: '/audio/La Course Sans Fin.mp3'},
  { title: 'Chaleur Électrique', src: '/audio/Chaleur Électrique.mp3'},
  { title: 'Course Vers aube', src: '/audio/Course Vers aube.mp3'},
  { title: 'Route Infini', src: '/audio/Route Infini.mp3'},

  { title: 'Impossible à Oublier', src: '/audio/Impossible à Oublier.mp3'},
  { title: 'Dans Mes Pensées', src: '/audio/Dans Mes Pensées.mp3'},
  { title: 'Sensation en Mouvement', src: '/audio/Sensation en Mouvement.mp3'},
  { title: 'Lumière Divine', src: '/audio/Lumière Divine.mp3'},
  { title: 'Rythme de Nuit', src: '/audio/Rythme de Nuit.mp3'},
  { title: 'Nuit en Festival', src: '/audio/Nuit en Festival.mp3'},
  { title: 'Dans Un Coin de Ma Tête', src: '/audio/Dans Un Coin de Ma Tête.mp3'},
  { title: 'Sans Gravité', src: '/audio/Sans Gravité.mp3'},
  { title: 'Loin de Toi', src: '/audio/Loin de Toi.mp3'},
  { title: 'Cœur Brisé', src: '/audio/Cœur Brisé.mp3'},
  { title: 'Symphonie des Étoiles', src: '/audio/Symphonie des Étoiles.mp3'},
  { title: 'Horizons Inconnus', src: '/audio/Horizons Inconnus.mp3'},
  { title: 'Échappée Vers lInfini', src: '/audio/Échappée Vers lInfini.mp3'},
  { title: 'Dans Tes Bras', src: '/audio/Dans Tes Bras.mp3'},
  { title: 'Échos dIncertitude', src: '/audio/Échos dIncertitude.mp3'},
  { title: 'Évasion Express', src: '/audio/Évasion Express.mp3'},
  { title: 'Dernier Rayon', src: '/audio/Dernier Rayon.mp3'},
  { title: 'Echo du passé', src: '/audio/Echo du passé.mp3'},
  { title: 'Liberté étoilé', src: '/audio/Liberté étoilé.mp3'},
  { title: 'Intouchable', src: '/audio/Intouchable.mp3'},
  { title: 'Oser Te Parler', src: '/audio/Oser Te Parler.mp3'},
  { title: 'Parfaite Illusion', src: '/audio/Parfaite Illusion.mp3'},
  { title: 'Nuit Magique', src: '/audio/Nuit Magique.mp3'},
  { title: 'Voyage à Travers les Âges', src: '/audio/Voyage à Travers les Âges.mp3'},
  { title: 'Libre comme le vent', src: '/audio/Libre comme le vent.mp3'},
  { title: 'Chasing the Horizon', src: '/audio/Chasing the Horizon.mp3'},
  { title: 'Mirages de Verre', src: '/audio/Mirages de Verre.mp3'},
  { title: 'Adrenaline High', src: '/audio/Adrenaline High.mp3'},
  { title: 'Pixel Dream', src: '/audio/Pixel Dream.mp3'},
  { title: 'Riding the Storm', src: '/audio/Riding the Storm.mp3'},
  { title: 'Tempete électrique', src: '/audio/Tempête Électrique.mp3'},
  { title: 'Velocity Surge', src: '/audio/Velocity Surge.mp3'},
  { title: 'The Ordinary 2.0', src: '/audio/The Ordinary 2.0.mp3'},
  { title: 'À toute allure', src: '/audio/À toute allure.mp3'},
  { title: 'Turbo Éclair', src: '/audio/Turbo Éclair.mp3'},
  { title: 'Reflets Égarés', src: '/audio/Reflets Égarés.mp3'},
  { title: 'Battements Électriques', src: '/audio/Battements Électriques.mp3'},
  { title: 'Battements en Club', src: '/audio/Battements en Club.mp3'},
  { title: 'Nuit en Cadillac 2.0', src: '/audio/Nuit en Cadillac 2.0.mp3'},
  { title: 'Fuel to the Fire', src: '/audio/Fuel to the Fire.mp3'},
  
  


  { title: 'Lightning in My Veins', src: '/audio/Lightning in My Veins.mp3'},
  { title: 'Lumières Égarées', src: '/audio/Lumières Égarées.mp3'},
  { title: 'Eclipse of the Speed', src: '/audio/Eclipse of the Speed.mp3'},
  { title: 'The Ordinary', src: '/audio/The Ordinary.mp3'},
  { title: 'Battement en Club', src: '/audio/Battements en Club.mp3'},
  { title: 'Vibes du DanceFloor', src: '/audio/Vibes du DanceFloor.mp3'},
  { title: 'TurboNuit', src: '/audio/TurboNuit.mp3'},
  { title: 'Résonance', src: '/audio/Résonance.mp3'},
  { title: 'Éclat dÉté', src: '/audio/Éclat d_Été.mp3'},
  { title: 'Lamour perdu', src: '/audio/L_amour perdu.mp3'},
  { title: 'Amours en Écho', src: '/audio/Amours en Écho.mp3'},
  { title: 'Éclats de Perfection', src: '/audio/Éclats de Perfection.mp3'},
  { title: 'Éclats dUne Nuit Perdue', src: '/audio/Éclats d_Une Nuit Perdue.mp3'},
  { title: 'Échappée Urbaine', src: '/audio/Échappée Urbaine.mp3'},
  { title: 'Noir Éternel', src: '/audio/Noir Éternel.mp3'},

  { title: 'Nuit en Cadillac', src: '/audio/Nuit en Cadillac.mp3'},
  { title: 'Nébuleuse Électrique', src: '/audio/Nébuleuse Électrique.mp3'},
  { title: 'Nuit Électrique', src: '/audio/Nuit Électrique.mp3'},
  { title: 'Vitesse de Lumière', src: '/audio/Vitesse de Lumière.mp3'},
  { title: 'Ombre', src: '/audio/Ombre.mp3'},
  { title: 'Horizons chromés', src: '/audio/Horizons chromés.mp3'},
  { title: 'Nuit éteinte', src: '/audio/Nuit éteinte.mp3'},
  { title: 'Une brise', src: '/audio/Une brise.mp3'},
  { title: 'Niveau d.arcade', src: '/audio/Niveau darcade.mp3'},
  { title: 'Éclats du Futur', src: '/audio/Éclats du Futur.mp3'},
  { title: 'Éclipse Infinie', src: '/audio/Éclipse Infinie.mp3'},
  { title: 'Fragments d espoir', src: '/audio/Fragments d.espoir.mp3'},
  { title: 'Nuit Éternelle', src: '/audio/Nuit Éternelle.mp3'},
  { title: 'Voyage vers l horizon', src: '/audio/Voyage vers l.horizon.mp3'},
];
// Nouvelle liste pour l'album Retro-Pulse
const albumTracks = [
  { title: 'Rêve de Lumière', src: '/audio/Rêve de Lumière.mp3' },
  { title: 'Éclats du Futur', src: '/audio/Éclats du Futur.mp3' },
  { title: 'Nuit en Cadillac', src: '/audio/Nuit en Cadillac.mp3' },
  { title: 'Ombre', src: '/audio/Ombre.mp3' },
  { title: 'Nuit éteinte', src: '/audio/Nuit éteinte.mp3' },
  { title: 'Éclats de Perfection', src: '/audio/Éclats de Perfection.mp3' },
  { title: 'Éclat d_Été', src: '/audio/Éclat d_Été.mp3' },
  { title: 'Résonance', src: '/audio/Résonance.mp3' },
  { title: 'TurboNuit', src: '/audio/TurboNuit.mp3' },
  { title: 'Nuit Électrique', src: '/audio/Nuit Électrique.mp3' },
  { title: 'Vibes du Dancefloor', src: '/audio/Vibes du Dancefloor.mp3' },
  { title: 'Lightning in My Veins', src: '/audio/Lightning in My Veins.mp3' },
  { title: 'Voyage à Travers les Âges', src: '/audio/Voyage à Travers les Âges.mp3' },
  { title: 'Loin du temps', src: '/audio/Loin du Temps.mp3' },
  { title: 'Niveau darcade', src: '/audio/Niveau darcade.mp3' },
  { title: 'Route Infini', src: '/audio/Route Infini.mp3' },
  { title: 'Dans La Nuit', src: '/audio/Dans la nuit.mp3' },
  { title: 'Sérénade des ombres', src: '/audio/Sérénade des ombres.mp3' },
];

function MusicPlayer({ url, isAlbumContext = false }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [progress, setProgress] = useState(0);
  const [showPlayer, setShowPlayer] = useState(true);
  const [minimized, setMinimized] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(() => {
    const currentTracks = isAlbumContext ? albumTracks : tracks;
    return currentTracks.findIndex(track => track.src === url);
  });
  const [duration, setDuration] = useState(0);
  const [isShuffling, setIsShuffling] = useState(false);
  const [isRepeating, setIsRepeating] = useState(false);
  const audioRef = useRef(null);

  const currentTracks = isAlbumContext ? albumTracks : tracks;

  useEffect(() => {
    const currentTrack = currentTracks.find(track => track.src === url);
    if (currentTrack) {
      setCurrentTrackIndex(currentTracks.indexOf(currentTrack));
    } else {
      setCurrentTrackIndex(0);
    }
  }, [url, isAlbumContext]);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch(error => console.error('Erreur lors de la lecture:', error));
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleProgressChange = (e) => {
    if (audioRef.current) {
      audioRef.current.currentTime = e.target.value;
      setProgress(e.target.value);
    }
  };

  const skipTrack = (direction) => {
    let newIndex = currentTrackIndex + direction;
    if (newIndex >= currentTracks.length) newIndex = 0;
    if (newIndex < 0) newIndex = currentTracks.length - 1;

    setCurrentTrackIndex(newIndex);
    const newTrack = currentTracks[newIndex];

    if (audioRef.current && newTrack) {
      audioRef.current.src = newTrack.src;
      audioRef.current.play().catch(error => console.error('Erreur lors de la lecture:', error));
      setIsPlaying(true);
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  const toggleShuffle = () => {
    setIsShuffling(!isShuffling);
  };

  const toggleRepeat = () => {
    setIsRepeating(!isRepeating);
  };

  const playNextTrack = () => {
    if (isShuffling) {
      const randomIndex = Math.floor(Math.random() * currentTracks.length);
      setCurrentTrackIndex(randomIndex);
    } else {
      skipTrack(1);
    }
  };

  useEffect(() => {
    const currentAudio = audioRef.current;
    if (currentAudio) {
      const updateProgress = () => setProgress(currentAudio.currentTime);
      const handleEnd = () => isRepeating ? currentAudio.play() : playNextTrack();
      const handleLoadedMetadata = () => setDuration(currentAudio.duration);

      currentAudio.addEventListener('timeupdate', updateProgress);
      currentAudio.addEventListener('ended', handleEnd);
      currentAudio.addEventListener('loadedmetadata', handleLoadedMetadata);

      return () => {
        currentAudio.removeEventListener('timeupdate', updateProgress);
        currentAudio.removeEventListener('ended', handleEnd);
        currentAudio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [currentTrackIndex, isRepeating]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    if (audioRef.current && currentTracks[currentTrackIndex]) {
      audioRef.current.src = currentTracks[currentTrackIndex].src;
      setProgress(0);
      audioRef.current.play().catch(error => console.error('Erreur lors de la lecture:', error));
      setIsPlaying(true);
    }
  }, [currentTrackIndex]);

  return (
    <>
      {showPlayer && (
        <PlayerContainer minimized={minimized}>
          <TrackInfo>
            <TrackTitle>{currentTracks[currentTrackIndex]?.title || 'Titre de la piste'}</TrackTitle>
            <TrackArtist>{isAlbumContext ? 'Pulse of the Night' : 'Artiste'}</TrackArtist>
          </TrackInfo>
          <audio 
            ref={audioRef} 
            controls={false}
          />
          
          <ProgressBar
            type="range"
            min="0"
            max={duration || 1}
            step="0.1"
            value={progress}
            onChange={handleProgressChange}
          />
          <ControlsRow>
            <TimeDisplay>{formatTime(progress)}</TimeDisplay>
            <TimeDisplay>{formatTime(duration)}</TimeDisplay>
          </ControlsRow>
          
          <PlayerControls>
            <Button onClick={toggleShuffle} style={{ color: isShuffling ? '#ff00ff' : '#00ffff' }}>
              <FaRandom />
            </Button>
            <Button onClick={() => skipTrack(-1)}>
              <FaBackward />
            </Button>
            <Button onClick={togglePlayPause}>
              {isPlaying ? <FaPause /> : <FaPlay />}
            </Button>
            <Button onClick={() => skipTrack(1)}>
              <FaForward />
            </Button>
            <Button onClick={toggleRepeat} style={{ color: isRepeating ? '#ff00ff' : '#00ffff' }}>
              <FaRedo />
            </Button>
          </PlayerControls>
          
          <ControlsRow>
            <Button onClick={() => setVolume(volume > 0 ? 0 : 0.5)}>
              {volume > 0 ? <FaVolumeUp /> : <FaVolumeMute />}
            </Button>
            <VolumeControl
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
            />
          </ControlsRow>
        </PlayerContainer>
      )}
      <ToggleButton onClick={() => setShowPlayer(!showPlayer)}>
        {showPlayer ? <FaChevronDown /> : <FaChevronUp />}
      </ToggleButton>
    </>
  );
}

export default MusicPlayer;