import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import SearchBar from './SearchBar';
import SocialShare from './SocialShare';

const tracks = [
  { title: 'Dans La Nuit', src: '/audio/Dans La nuit.mp3', isNew: true },
  { title: 'Loin du temps', src: '/audio/Loin Du Temps.mp3', isNew: true },
  { title: 'Vortex Nocturne', src: '/audio/Vortex Nocturne.mp3', isNew: true },
  { title: 'La Course Sans Fin', src: '/audio/La Course Sans Fin.mp3', isNew: true },
  { title: 'Chaleur Électrique', src: '/audio/Chaleur Électrique.mp3', isNew: true },
  { title: 'Course Vers aube', src: '/audio/Course Vers aube.mp3', isNew: true },
  { title: 'Route Infini', src: '/audio/Route Infini.mp3', isNew: true },

  { title: 'Impossible à Oublier', src: '/audio/Impossible à Oublier.mp3', recommended: true },
  { title: 'Dans Mes Pensées', src: '/audio/Dans Mes Pensées.mp3', recommended: true },
  { title: 'Sensation en Mouvement', src: '/audio/Sensation en Mouvement.mp3', recommended: true },
  { title: 'Lumière Divine', src: '/audio/Lumière Divine.mp3'},
  { title: 'Rythme de Nuit', src: '/audio/Rythme de Nuit.mp3', recommended: true },
  { title: 'Nuit en Festival', src: '/audio/Nuit en Festival.mp3'},
  { title: 'Dans Un Coin de Ma Tête', src: '/audio/Dans Un Coin de Ma Tête.mp3'},
  { title: 'Sans Gravité', src: '/audio/Sans Gravité.mp3'},
  { title: 'Loin de Toi', src: '/audio/Loin de Toi.mp3'},
  { title: 'Cœur Brisé', src: '/audio/Cœur Brisé.mp3'},
  { title: 'Symphonie des Étoiles', src: '/audio/Symphonie des Étoiles.mp3'},
  { title: 'Horizons Inconnus', src: '/audio/Horizons Inconnus.mp3', recommended: true },
  { title: 'Échappée Vers lInfini', src: '/audio/Échappée Vers lInfini.mp3'},
  { title: 'Dans Tes Bras', src: '/audio/Dans Tes Bras.mp3', recommended: true },
  { title: 'Échos dIncertitude', src: '/audio/Échos dIncertitude.mp3'},
  { title: 'Évasion Express', src: '/audio/Évasion Express.mp3', recommended: true },
  { title: 'Dernier Rayon', src: '/audio/Dernier Rayon.mp3', recommended: true },
  { title: 'Echo du passé', src: '/audio/Echo du passé.mp3'},
  { title: 'Liberté étoilé', src: '/audio/Liberté étoilé.mp3'},
  { title: 'Intouchable', src: '/audio/Intouchable.mp3', recommended: true},
  { title: 'Oser Te Parler', src: '/audio/Oser Te Parler.mp3', recommended: true},
  { title: 'Parfaite Illusion', src: '/audio/Parfaite Illusion.mp3', recommended: true},
  { title: 'Nuit Magique', src: '/audio/Nuit Magique.mp3', recommended: true},
  { title: 'Voyage à Travers les Âges', src: '/audio/Voyage à Travers les Âges.mp3', recommended: true },
  { title: 'Libre comme le vent', src: '/audio/Libre comme le vent.mp3', recommended: true },
  { title: 'Chasing the Horizon', src: '/audio/Chasing the Horizon.mp3'},
  { title: 'Mirages de Verre', src: '/audio/Mirages de Verre.mp3', recommended: true },
  { title: 'Adrenaline High', src: '/audio/Adrenaline High.mp3'},
  { title: 'Pixel Dream', src: '/audio/Pixel Dream.mp3', recommended: true },
  { title: 'Riding the Storm', src: '/audio/Riding the Storm.mp3'},
  { title: 'Tempete électrique', src: '/audio/Tempête Électrique.mp3'},
  { title: 'Velocity Surge', src: '/audio/Velocity Surge.mp3'},
  { title: 'The Ordinary 2.0', src: '/audio/The Ordinary 2.0.mp3'},
  { title: 'À toute allure', src: '/audio/À toute allure.mp3'},
  { title: 'Turbo Éclair', src: '/audio/Turbo Éclair.mp3'},
  { title: 'Reflets Égarés', src: '/audio/Reflets Égarés.mp3'},
  { title: 'Battements Électriques', src: '/audio/Battements Électriques.mp3'},
  { title: 'Battements en Club', src: '/audio/Battements en Club.mp3'},
  { title: 'Nuit en Cadillac 2.0', src: '/audio/Nuit en Cadillac 2.0.mp3'},
  { title: 'Fuel to the Fire', src: '/audio/Fuel to the Fire.mp3'},
  { title: 'Lightning in My Veins', src: '/audio/Lightning in My Veins.mp3', recommended: true },
  { title: 'Lumières Égarées', src: '/audio/Lumières Égarées.mp3', recommended: true },
  { title: 'Eclipse of the Speed', src: '/audio/Eclipse of the Speed.mp3', recommended: true },
  { title: 'The Ordinary', src: '/audio/The Ordinary.mp3', recommended: true },
  { title: 'Battement en Club', src: '/audio/Battements en Club.mp3'},
  { title: 'Vibes du DanceFloor', src: '/audio/Vibes du DanceFloor.mp3' },
  { title: 'TurboNuit', src: '/audio/TurboNuit.mp3', recommended: true },
  { title: 'Résonance', src: '/audio/Résonance.mp3', recommended: true },
  { title: 'Éclat dÉté', src: '/audio/Éclat d_Été.mp3', recommended: true },
  { title: 'Lamour perdu', src: '/audio/L_amour perdu.mp3' },
  { title: 'Amours en Écho', src: '/audio/Amours en Écho.mp3' },
  { title: 'Éclats de Perfection', src: '/audio/Éclats de Perfection.mp3', recommended: true },
  { title: 'Éclats dUne Nuit Perdue', src: '/audio/Éclats d_Une Nuit Perdue.mp3' },
  { title: 'Échappée Urbaine', src: '/audio/Échappée Urbaine.mp3' },
  { title: 'Noir Éternel', src: '/audio/Noir Éternel.mp3' },
  { title: 'Nuit en Cadillac', src: '/audio/Nuit en Cadillac.mp3', recommended: true },
  { title: 'Nébuleuse Électrique', src: '/audio/Nébuleuse Électrique.mp3' },
  { title: 'Nuit Électrique', src: '/audio/Nuit Électrique.mp3', recommended: true },
  { title: 'Vitesse de Lumière', src: '/audio/Vitesse de Lumière.mp3' },
  { title: 'Ombre', src: '/audio/Ombre.mp3', recommended: true },
  { title: 'Horizons chromés', src: '/audio/Horizons chromés.mp3', recommended: true },
  { title: 'Nuit éteinte', src: '/audio/Nuit éteinte.mp3', recommended: true },
  { title: 'Une brise', src: '/audio/Une brise.mp3' },
  { title: 'Niveau d.arcade', src: '/audio/Niveau darcade.mp3', recommended: true },
  { title: 'Éclats du Futur', src: '/audio/Éclats du Futur.mp3', recommended: true },
  { title: 'Éclipse Infinie', src: '/audio/Éclipse Infinie.mp3' },
  { title: 'Fragments d espoir', src: '/audio/Fragments d.espoir.mp3', recommended: true },
  { title: 'Nuit Éternelle', src: '/audio/Nuit Éternelle.mp3' },
  { title: 'Voyage vers l horizon', src: '/audio/Voyage vers l.horizon.mp3', isEnglish: false },
];

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  background: linear-gradient(to bottom, #0f0c29, #302b63, #24243e);
  min-height: 100vh;
  font-family: 'Orbitron', sans-serif;
`;

const SearchSection = styled.section`
  width: 100%;
  margin-bottom: 40px;
`;

const TextSection = styled.section`
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1rem;
  color: #00ffff;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.8);
`;

const Section = styled.section`
  width: 100%;
  margin-bottom: 60px;
  text-align: center;

  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ff00ff;
    text-shadow: 0 0 10px rgba(255, 0, 255, 0.8), 0 0 20px rgba(255, 0, 255, 0.6);
  }
`;

const List = styled(motion.ul)`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
  justify-content: center;
`;

const TrackItem = styled(motion.li)`
  cursor: pointer;
  padding: 15px;
  border: 2px solid ${props => props.isNew ? '#00ffff' : '#ff00ff'};
  border-radius: 8px;
  background: ${props => props.isNew ? 'rgba(0, 255, 255, 0.1)' : 'rgba(255, 0, 255, 0.1)'};
  color: ${props => props.isNew ? '#00ffff' : '#ff00ff'};
  text-shadow: 0 0 10px rgba(255, 0, 255, 0.8), 0 0 20px rgba(255, 0, 255, 0.6);
  transition: all 0.3s ease;
  box-shadow: 0 0 15px ${props => props.isNew ? '#00ffff' : '#ff00ff'};
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &:hover {
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0 5px 20px ${props => props.isNew ? '#00ffff' : '#ff00ff'};
  }
`;

const TrackTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
`;

const Button = styled.button`
  background: transparent;
  color: #00ffff;
  border: 1px solid #00ffff;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9em;
  font-family: 'Orbitron', sans-serif;

  &:hover {
    background: rgba(0, 255, 255, 0.1);
    box-shadow: 0 0 12px rgba(0, 255, 255, 1);
  }
`;

const Tag = styled.span`
  display: inline-block;
  background: ${props => props.isNew ? '#00ffff' : '#ff00ff'};
  color: #000;
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 0.7rem;
  margin-top: 5px;
  text-shadow: none;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
`;

const FilterButton = styled.button`
  background: ${props => props.active ? '#ff00ff' : 'transparent'};
  color: ${props => props.active ? '#000' : '#ff00ff'};
  border: 1px solid #ff00ff;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Orbitron', sans-serif;

  &:hover {
    background: ${props => props.active ? '#ff00ff' : 'rgba(255, 0, 255, 0.2)'};
    box-shadow: 0 0 10px rgba(255, 0, 255, 0.8);
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  background: transparent;
  color: #00ffff;
  border: 1px solid #00ffff;
  border-radius: 6px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Orbitron', sans-serif;

  &:hover {
    background: rgba(0, 255, 255, 0.2);
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

function TrackListPage({ onSelectTrack }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const tracksPerPage = 12;

  const filteredTracks = useMemo(() => {
    return tracks.filter(track => {
      const matchesSearch = track.title.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesFilter = 
        filter === 'all' || 
        (filter === 'new' && track.isNew) || 
        (filter === 'recommended' && track.recommended) ||
        (filter === 'other' && !track.isNew && !track.recommended) ||
        (filter === 'english' && track.isEnglish);
      return matchesSearch && matchesFilter;
    });
  }, [searchTerm, filter]);

  const currentTracks = useMemo(() => {
    const indexOfLastTrack = currentPage * tracksPerPage;
    const indexOfFirstTrack = indexOfLastTrack - tracksPerPage;
    return filteredTracks.slice(indexOfFirstTrack, indexOfLastTrack);
  }, [filteredTracks, currentPage]);

  const pageCount = Math.ceil(filteredTracks.length / tracksPerPage);

  const handlePageChange = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  return (
    <PageContainer>
      <SearchSection>
        <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
      </SearchSection>

      <TextSection>
        Bonne écoute. Découvrez notre sélection de pistes audio ci-dessous.
      </TextSection>

      <FilterContainer>
        <FilterButton active={filter === 'all'} onClick={() => setFilter('all')}>All</FilterButton>
        <FilterButton active={filter === 'new'} onClick={() => setFilter('new')}>New Releases</FilterButton>
        <FilterButton active={filter === 'recommended'} onClick={() => setFilter('recommended')}>Recommended</FilterButton>
        <FilterButton active={filter === 'other'} onClick={() => setFilter('other')}>Other</FilterButton>
        <FilterButton active={filter === 'english'} onClick={() => setFilter('english')}>English</FilterButton>
      </FilterContainer>
      <Pagination>
        <PageButton 
          onClick={() => handlePageChange(currentPage - 1)} 
          disabled={currentPage === 1}
        >
          Previous
        </PageButton>
        {[...Array(pageCount)].map((_, i) => (
          <PageButton 
            key={i} 
            onClick={() => handlePageChange(i + 1)}
            style={{
              background: currentPage === i + 1 ? 'rgba(0, 255, 255, 0.2)' : 'transparent'
            }}
          >
            {i + 1}
          </PageButton>
        ))}
        <PageButton 
          onClick={() => handlePageChange(currentPage + 1)} 
          disabled={currentPage === pageCount}
        >
          Next
        </PageButton>
      </Pagination>
      <Section>
        <AnimatePresence>
          <List
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {currentTracks.map((track, index) => (
              <TrackItem
                key={track.title}
                isNew={track.isNew}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, delay: index * 0.05 }}
              >
                <TrackTitle>{track.title}</TrackTitle>
                {track.isNew && <Tag isNew>New</Tag>}
                {track.recommended && <Tag>Recommended</Tag>}
                <ButtonContainer>
                  <Button onClick={() => onSelectTrack(track.src, track.title)}>Play</Button>
                  <Button as="a" href={track.src} download>Download</Button>
                  <SocialShare url={track.src} title={track.title} />
                </ButtonContainer>
              </TrackItem>
            ))}
          </List>
        </AnimatePresence>
      </Section>


    </PageContainer>
  );
}

export default TrackListPage;