import React, { useState } from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import MusicPlayer from './components/MusicPlayer';
import AlbumPage from './components/AlbumPage';
import TrackList from './components/TrackList';
import Doc from './components/Doc';
import TermsOfUse from './components/TermOfUse';
import { FaAngleDown, FaAngleUp, FaBars, FaTimes } from 'react-icons/fa';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Orbitron:wght@400;700&display=swap');

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background: #000;
  }
`;

const retroGradient = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const AppContainer = styled.div`
  background: linear-gradient(270deg, #ff00ff, #00ffff, #ff00ff);
  background-size: 600% 600%;
  animation: ${retroGradient} 30s ease infinite;
  color: #e0e0e0;
  font-family: 'Orbitron', sans-serif;
  text-align: center;
  min-height: 100vh;
  padding: 20px;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: repeating-linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.15),
      rgba(0, 0, 0, 0.15) 1px,
      transparent 1px,
      transparent 2px
    );
    pointer-events: none;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const glowAnimation = keyframes`
  0% { text-shadow: 0 0 5px #ff00ff, 0 0 10px #ff00ff, 0 0 15px #ff00ff, 0 0 20px #ff00ff, 0 0 35px #ff00ff, 0 0 40px #ff00ff, 0 0 50px #ff00ff, 0 0 75px #ff00ff; }
  50% { text-shadow: 0 0 2.5px #ff00ff, 0 0 5px #ff00ff, 0 0 7.5px #ff00ff, 0 0 10px #ff00ff, 0 0 17.5px #ff00ff, 0 0 20px #ff00ff, 0 0 25px #ff00ff, 0 0 37.5px #ff00ff; }
  100% { text-shadow: 0 0 5px #ff00ff, 0 0 10px #ff00ff, 0 0 15px #ff00ff, 0 0 20px #ff00ff, 0 0 35px #ff00ff, 0 0 40px #ff00ff, 0 0 50px #ff00ff, 0 0 75px #ff00ff; }
`;

const Title = styled.h1`
  font-family: 'Press Start 2P', cursive;
  font-size: 4rem;
  color: #fff;
  text-shadow: 0 0 5px #ff00ff, 0 0 10px #ff00ff, 0 0 15px #ff00ff, 0 0 20px #ff00ff, 0 0 35px #ff00ff, 0 0 40px #ff00ff, 0 0 50px #ff00ff, 0 0 75px #ff00ff;
  animation: ${glowAnimation} 2s ease-in-out infinite;
  z-index: 1;
  position: relative;
  margin: 0;
  letter-spacing: 4px;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.span`
  display: block;
  font-size: 1.2rem;
  color: #00ffd5;
  text-shadow: 0 0 5px rgba(0, 255, 213, 0.7), 0 0 10px rgba(0, 255, 213, 0.5), 0 0 15px rgba(0, 255, 213, 0.3);
  margin-top: 10px;
  letter-spacing: 2px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Nav = styled.nav`
  margin-top: 30px;
  z-index: 1002; 
  position: relative;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    background: rgba(0, 0, 0, 0.9);
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 60px 20px;
    text-align: center;
    z-index: 1002; 
  }

  a {
    color: #00ffd5;
    text-decoration: none;
    margin: 0 20px;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    padding: 10px 15px;
    border: 2px solid transparent;
    position: relative;
    overflow: hidden;

    @media (max-width: 768px) {
      display: block;
      margin: 20px 0;
      font-size: 1.5rem;
    }

    &:hover {
      color: #ff007f;
      border-color: #ff007f;
      text-shadow: 0 0 5px #ff007f, 0 0 10px #ff007f;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, transparent, #ff00ff);
      transition: 0.5s;
    }

    &:hover::before {
      left: 100%;
    }
  }
`;

const Content = styled.div`
  z-index: 1;
  position: relative;
  margin-top: 40px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(255, 0, 255, 0.3), 0 0 40px rgba(0, 255, 255, 0.2);

  @media (max-width: 768px) {
    padding: 15px;
    margin-top: 20px;
  }
`;

const MobileMenuButton = styled.button`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    background: linear-gradient(45deg, #ff00ff, #00ffff);
    color: white;
    border: none;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    cursor: pointer;
    position: fixed; 
    top: 15px;
    right: 15px;
    z-index: 1003; 
    box-shadow: 0 0 15px rgba(255, 0, 255, 0.7), 0 0 30px rgba(0, 255, 255, 0.5);
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 0 20px rgba(255, 0, 255, 1), 0 0 40px rgba(0, 255, 255, 0.8);
    }
  }
`;

function App() {
  const [trackUrl, setTrackUrl] = useState(null);
  const [trackTitle, setTrackTitle] = useState('');
  const [isPlayerVisible, setIsPlayerVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSelectTrack = (url, title) => {
    setTrackUrl(url);
    setTrackTitle(title);
    setIsPlayerVisible(true);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <Router>
      <GlobalStyle />
      <AppContainer>
        <Title className="glow">Retro-pulse</Title>
        <Subtitle>by @thep40l0</Subtitle>
        <MobileMenuButton onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </MobileMenuButton>
        <Nav isOpen={isMenuOpen}>
          <Link to="/" onClick={() => setIsMenuOpen(false)}>Home</Link>
          <Link to="/album" onClick={() => setIsMenuOpen(false)}>Album</Link>
          <Link to="/doc" onClick={() => setIsMenuOpen(false)}>Document</Link>
          <Link to="/terms" onClick={() => setIsMenuOpen(false)}>Conditions d'utilisation</Link>
        </Nav>
        <Content>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  {isPlayerVisible && <MusicPlayer url={trackUrl} title={trackTitle} />}
                  <TrackList onSelectTrack={handleSelectTrack} />
                </>
              }
            />
            <Route path="/album" element={<AlbumPage onSelectTrack={handleSelectTrack} />} />
            <Route path="/doc" element={<Doc />} />
            <Route path="/terms" element={<TermsOfUse />} />
          </Routes>
        </Content>
      </AppContainer>
    </Router>
  );
}

export default App;