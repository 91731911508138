import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import MusicPlayer from './MusicPlayer';

const albumTracks = [
  { title: 'Rêve de Lumière', src: '/audio/Rêve de Lumière.mp3' },
  { title: 'Éclats du Futur', src: '/audio/Éclats du Futur.mp3' },
  { title: 'Nuit en Cadillac', src: '/audio/Nuit en Cadillac.mp3' },
  { title: 'Ombre', src: '/audio/Ombre.mp3' },
  { title: 'Nuit éteinte', src: '/audio/Nuit éteinte.mp3' },
  { title: 'Éclats de Perfection', src: '/audio/Éclats de Perfection.mp3' },
  { title: 'Éclat d_Été', src: '/audio/Éclat d_Été.mp3' },
  { title: 'Résonance', src: '/audio/Résonance.mp3' },
  { title: 'TurboNuit', src: '/audio/TurboNuit.mp3' },
  { title: 'Nuit Électrique', src: '/audio/Nuit Électrique.mp3' },
  { title: 'Vibes du Dancefloor', src: '/audio/Vibes du Dancefloor.mp3' },
  { title: 'Lightning in My Veins', src: '/audio/Lightning in My Veins.mp3' },
  { title: 'Dans La Nuit', src: '/audio/Dans la nuit.mp3'},
  { title: 'Loin du temps', src: '/audio/Loin du Temps.mp3'},
  { title: 'Voyage à Travers les Âges', src: '/audio/Voyage à Travers les Âges.mp3' },
  { title: 'Route Infini', src: '/audio/Route Infini.mp3' },
];

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: #fff;
  max-width: 800px;
  margin: 0 auto;
  background: linear-gradient(to bottom, #0f0c29, #302b63, #24243e);
  min-height: 100vh;
`;

const AlbumTitle = styled.h2`
  font-size: 3rem;
  color: #ff00ff;
  text-shadow: 0 0 10px rgba(255, 0, 255, 0.8), 0 0 20px rgba(255, 0, 255, 0.6),
               0 0 30px rgba(255, 0, 255, 0.4), 0 0 40px rgba(255, 0, 255, 0.2);
  margin-bottom: 20px;
  font-family: 'Orbitron', sans-serif;
`;

const AlbumCoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
`;

const AlbumCover = styled.div`
  width: 300px;
  height: 300px;
  background-image: url('/icons/5_000.jpg');
  background-size: cover;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.5);

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 30px rgba(0, 255, 255, 0.8), 0 0 50px rgba(255, 0, 255, 0.6);
  }
`;

const AlbumDescription = styled.p`
  text-align: center;
  font-size: 1rem;
  color: #00ffff;
  margin-top: 10px;
  max-width: 300px;
  line-height: 1.5;
  background: linear-gradient(90deg, #ff00ff, #00ffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Roboto', sans-serif;
`;

const TrackList = styled(motion.ul)`
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 500px;
`;

const TrackItem = styled(motion.li)`
  cursor: pointer;
  padding: 15px;
  border: 2px solid #00ffff;
  border-radius: 8px;
  background: rgba(0, 255, 255, 0.1);
  color: #00ffff;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  font-family: 'Orbitron', sans-serif;

  &:hover {
    transform: translateX(10px);
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.8), 0 0 25px rgba(255, 0, 255, 0.6);
    background: rgba(0, 255, 255, 0.2);
  }
`;

const SunIcon = styled.svg`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  filter: drop-shadow(0 0 10px rgba(255, 255, 0, 0.8));
`;

function AlbumPage() {
  const [isTrackListVisible, setIsTrackListVisible] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(null);

  const toggleTrackList = () => {
    setIsTrackListVisible(!isTrackListVisible);
  };

  const handleSelectTrack = (track) => {
    setCurrentTrack(track);
  };

  return (
    <PageContainer>
      <AlbumTitle>Pulse of the Night</AlbumTitle>
      <AlbumCoverContainer>
        <SunIcon width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="4" fill="#FFFF00" />
          <path d="M12 2V4M12 20V22M4 12H2M6.31 6.31L4.89 4.89M17.69 6.31L19.11 4.89M6.31 17.69L4.89 19.11M17.69 17.69L19.11 19.11M22 12H20" stroke="#FFFF00" strokeWidth="2" strokeLinecap="round" />
        </SunIcon>
        <AlbumCover onClick={toggleTrackList} />
        <AlbumDescription>
          Cliquez sur l'image pour entrer.
          
          Pulse of the Night est un voyage musical immersif.
        </AlbumDescription>
      </AlbumCoverContainer>
      <AnimatePresence>
        {isTrackListVisible && (
          <TrackList
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            {albumTracks.map((track, index) => (
              <TrackItem
                key={track.title}
                onClick={() => handleSelectTrack(track)}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                {track.title}
              </TrackItem>
            ))}
          </TrackList>
        )}
      </AnimatePresence>
      {currentTrack && (
        <MusicPlayer 
          url={currentTrack.src} 
          isAlbumContext={true}
        />
      )}
    </PageContainer>
  );
}

export default AlbumPage;