import React from 'react';
import styled, { keyframes } from 'styled-components';

const neonPulse = keyframes`
  0%, 100% {
    text-shadow: 0 0 10px #ff00de, 0 0 20px #ff00de, 0 0 30px #ff00de;
  }
  50% {
    text-shadow: 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de;
  }
`;

const TermsContainer = styled.div`
  text-align: left;
  padding: 30px;
  background: linear-gradient(45deg, #0f0c29, #302b63, #24243e);
  border-radius: 15px;
  color: #00ffff;
  font-family: 'Orbitron', sans-serif;
  line-height: 1.6;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.5);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: repeating-linear-gradient(
      to bottom,
      transparent,
      transparent 20px,
      rgba(0, 255, 255, 0.03) 20px,
      rgba(0, 255, 255, 0.03) 40px
    );
    pointer-events: none;
  }
`;

const TermsTitle = styled.h2`
  color: #ff00de;
  margin-bottom: 30px;
  font-family: 'Press Start 2P', cursive;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  animation: ${neonPulse} 2s infinite;
`;

const TermsSection = styled.section`
  margin-bottom: 30px;
  background: rgba(0, 0, 0, 0.3);
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #00ffff;
`;

const SectionTitle = styled.h3`
  color: #00ffff;
  margin-bottom: 15px;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const TermsList = styled.ol`
  padding-left: 20px;
  list-style-type: none;
  counter-reset: item;
`;

const TermsItem = styled.li`
  margin-bottom: 15px;
  position: relative;
  padding-left: 30px;

  &::before {
    content: counter(item);
    counter-increment: item;
    position: absolute;
    left: 0;
    top: 0;
    background: #ff00de;
    color: #000;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: bold;
  }
`;

const StrongText = styled.strong`
  color: #ff00de;
`;

const TermsOfUse = () => {
  return (
    <TermsContainer>
      <TermsTitle>Conditions d'utilisation</TermsTitle>
      <TermsSection>
        <SectionTitle>Propriété des Contenus :</SectionTitle>
        <TermsList>
          <TermsItem>
            <StrongText>Propriété intellectuelle</StrongText> : Toutes les chansons et contenus audio présents sur le site Retropulse sont la propriété exclusive du propriétaire du site.
          </TermsItem>
          <TermsItem>
            <StrongText>Utilisation autorisée</StrongText> : Vous êtes autorisé à écouter et télécharger ces chansons uniquement pour un usage personnel et non commercial. Toute autre utilisation, y compris la distribution, la modification ou l'utilisation commerciale, nécessite une autorisation écrite préalable du propriétaire.
          </TermsItem>
          <TermsItem>
            <StrongText>Interdiction de vol</StrongText> : Il est strictement interdit de revendre, redistribuer ou utiliser les chansons à des fins commerciales sans ma permission. Toute violation de ces droits pourra entraîner des poursuites.
          </TermsItem>
        </TermsList>
      </TermsSection>
    </TermsContainer>
  );
};

export default TermsOfUse;